<template>
  <div>
    <faq />
  </div>
</template>

<script>
import Faq from '@/components/faqs/Faq.vue';
import { methods } from 'vue-echarts';
import store from '@/store/index';
export default {
  components: {
    Faq,
  },
  created() {
    store.dispatch('app-dashboard/fetchSubscribeDashboard').then(() => {});
  },
};
</script>

<style></style>
